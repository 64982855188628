import { USER_DATA, SIGN_IN, LOG_OUT, SIGN_IN_ERROR } from '../constants';


const INIT_STATE = {
  errorMessage: '',
  authToken: '',
  userName: '',
  userData: '',
};

const LoginReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case USER_DATA:
      return {
        ...state,
        userName: action.payload.users.name,
        userData: action.payload.users,
      };
    case SIGN_IN:
      console.log(action);
      return {
        ...state,
        authToken: action.payload.accessToken,
        userName: action.payload.users.name,
        userData: action.payload.users,
      };
    case SIGN_IN_ERROR:
      return {
        ...state,
        errorMessage: "Invalid e-mail!"
      };
    case LOG_OUT:
      return INIT_STATE;
   
    default:
      return state;
  }
};

export default LoginReducer;
