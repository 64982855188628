import React, { lazy } from "react";
import { Navigate } from "react-router-dom";

/* ***Layouts**** */
const FullLayout = lazy(() => import("../layouts/full-layout/FullLayout"));
const FullLayoutIframe = lazy(() =>
  import('../layouts/full-layout-iframe/FullLayout')
)
const BlankLayout = lazy(() => import('../layouts/blank-layout/BlankLayout'))
const BlankLayoutNoNotify = lazy(() => import('../layouts/blank-layout/BlankLayoutNoNotify'))

/* ***End Layouts**** */
const Home = lazy(() => import("../views"));
const Error = lazy(() => import("../views/authentication/Error"));
const Login = lazy(() => import("../views/authentication/Login"));
const RedirectLogin = lazy(() => import("../views/authentication/RedirectLogin"));
const SignUp = lazy(() => import("../views/authentication/SignUp"));
const ForgotPassword = lazy(() => import("../views/authentication/ForgotPassword"));
const ResetPassword = lazy(() => import("../views/authentication/ResetPassword"));
const PasswordResetSent = lazy(() => import("../views/authentication/PasswordResetSent"));
const EmailVerification = lazy(() => import("../views/authentication/EmailVerification.js"));
const SignUpForm = lazy(() => import("../views/authentication/SignUpForm"));
const SignUpSimpleForm = lazy(() => import("../views/authentication/signUpSimpleForm"));
const SignUpFullForm = lazy(() => import("../views/authentication/signUpFullForm"));
const EmailNotFound = lazy(() => import("../views/authentication/email-not-found/Page"));

/*** Tools */

const Tools = lazy(() => import('../views/tools/Page'))
const FirstProduct = lazy(() => import('../views/tools/Components/pricing/Components/FirstProduct'))
const Pricing = lazy(() => import('../views/tools/Components/pricing'))
const PricingAddProduct = lazy(() => import('../views/tools/Components/pricing/Components/PricingToolWizard'))
const ProductResult = lazy(() => import('../views/tools/Components/pricing/Components/ProductResult'))
const ProductsList = lazy(() => import('../views/tools/Components/pricing/Components/ProductsList'))
const ProductEdition = lazy(() => import('../views/tools/Components/pricing/Components/ProductEdition'))

/* ****Pages***** */
const Community = lazy(() => import('../views/community/Page'))
const StoreCustomizer = lazy(() => import("../views/storeCustomizer/Page"));
const StoreAutomation = lazy(() => import("../views/storeAutomation/Page"));
const StoreAutomationWelcome = lazy(() => import("../views/storeAutomation/Welcome"));
const StoreInstall = lazy(() => import("../views/storeInstall/Page"));
const ProductInstall = lazy(() => import("../views/productInstall/Page"));
const StoreTyp = lazy(() => import("../views/storeCustomizer/ThankyouPage"));
const Setup = lazy(() => import("../views/setup/Page"));
const Contact = lazy(() => import("../views/contact/Page"));


/* ****Routes***** */

const Router = [
  {
    path: '/',
    element: <BlankLayoutNoNotify />,
    children: [
      { path: '/', element: <Home /> },
      { path: '*', element: <Navigate to="/auth/404" /> }
    ]
  },
  { 
    path: '/tools',
    element: <FullLayout />,
    children: [
      { path: '/tools', element: <Tools /> },
      { path: '/tools/pricing-tool/first-product', element: <FirstProduct /> },
      { path: '/tools/pricing-tool/add', element: <PricingAddProduct /> },
      { path: '/tools/pricing-tool', element: <Pricing /> },
      { path: '/tools/pricing-tool/result/:index', element: <ProductResult /> },
      { path: '/tools/pricing-tool/products-list', element: <ProductsList /> },
      { path: '/tools/pricing-tool/edition/:index', element: <ProductEdition /> },
      { path: '/tools/pricing', element: <Pricing /> }
    ]
  },
  {
    path: "/community",
    element: <FullLayoutIframe />,
    children: [
      { path: '/community',  element: <Community /> }
    ]
  },
  {
    path: "/store/automation",
    element: <FullLayoutIframe />,
    children: [
      {
        path: "/store/automation",
        element: <StoreAutomation />,
      },
      {
        path: "/store/automation/welcome",
        element: <StoreAutomationWelcome />
      }
    ],
  },
  {
    path: "/store/install",
    element: <FullLayoutIframe />,
    children: [
      {
        path: "/store/install",
        element: <StoreInstall />,
      }
    ],
  },
  {
    path: "/store/product/install",
    element: <FullLayoutIframe />,
    children: [
      {
        path: "/store/product/install",
        element: <ProductInstall />,
      }
    ],
  },
  {
    path: "/store/customize",
    element: <FullLayoutIframe />,
    children: [
      {
        path: "/store/customize",
        element: <StoreCustomizer propEmail={false} propIsCompleted={false} />,
      }
    ],
  },
  {
    path: "/setup",
    element: <FullLayout />,
    children: [
      {
        path: "/setup",
        element: <Setup />,
      }
    ],
  },
  {
    path: "/thankyou",
    element: <BlankLayout />,
    children: [
      {
        path: "/thankyou",
        element: <StoreTyp />,
      }
    ],
  },
  {
    path: "/support",
    element: <BlankLayout />,
    children: [
      {
        path: "/support",
        element: <Contact />,
      }
    ],
  },
  {
    path: "/auth",
    element: <BlankLayout />,
    children: [
      { path: '404', element: <Error /> },
      { path: '*', element: <Navigate to="/auth/404" /> },
      { path: '/auth/login', element: <Login /> },
      { path: '/auth/redirectlogin/:accessToken', element: <RedirectLogin /> },
      { path: '/auth/signup', element: <SignUp /> },
      { path: '/auth/forgotpassword', element: <ForgotPassword /> },
      { path: '/auth/resetpassword', element: <ResetPassword /> },
      { path: '/auth/resetpasswordsent', element: <PasswordResetSent /> },
      { path: '/auth/verifyEmail', element: <EmailVerification /> },
      { path: '/auth/signup-form', element: <SignUpForm /> },
      { path: '/auth/signup-simple-form', element: <SignUpSimpleForm /> },
      { path: '/auth/emailnotfound', element: <EmailNotFound />}
    ]
  },
  {
    path: "/auth/signup",
    element: <BlankLayoutNoNotify />,
    children: [
      { path: '/auth/signup/form', element: <SignUpForm /> },
      { path: '/auth/signup/simple-form', element: <SignUpSimpleForm /> },
      { path: '/auth/signup/full-form', element: <SignUpFullForm /> }
    ]
  }
]

export default Router;